import { getDirectoryItemByIDs } from '~/modules/directory/api/get-directory-items-admin.server';

import invariant from 'tiny-invariant';
import type { LoaderFunction } from '@remix-run/node';
import type { DirectoryItemAdmin } from '~/modules/directory/type';

export interface LoaderResult {
    items: DirectoryItemAdmin[];
}

export const loader: LoaderFunction = async ({
    request,
    context,
}): Promise<LoaderResult | null> => {
    const url = new URL(request.url);
    const idsParams = url.searchParams.getAll('IDs');
    if (!idsParams || !idsParams.length) {
        invariant(idsParams, 'IDs param is required.');
    }

    const ids = idsParams.map((idParam) => parseInt(idParam.toString()));

    const [items] = await Promise.all([
        getDirectoryItemByIDs({
            ...context,
            IDs: ids,
        }),
    ]);

    return { items };
};

export const QUERY_DIRECTORY_ITEMS_ADMIN =
    '/remix-api/directory/get-directory-items-admin';
