import type { User } from '~/server/types';
import { PermissionEnum } from 'generated/graphql';
import type { ContentItemType } from '~/modules/root/util/item-link-helpers';
import {
    getContentEditorLink,
    getRepositionThumbLink,
} from '~/modules/root/util/item-link-helpers';
import { getPermissions } from '~/modules/user/hook/use-permissions';
import type { EditableItem } from '../type';
import type { ProfessionalTileFragment } from 'generated/graphql';

interface EditableItemProps {
    itemType: ContentItemType;
    link: string;
    professionalID: number;
    professionalLink: string;
    user?: User;
}

export const getEditableItem = (props: EditableItemProps): EditableItem => {
    return {
        canEdit: props.user
            ? getPermissions(
                  props.user,
                  PermissionEnum.BmProfileEdit,
                  props.professionalID
              )
            : false,
        bmEditorLink: getContentEditorLink(
            props.itemType,
            props.link,
            props.professionalLink
        ),
        repositionThumbLink: getRepositionThumbLink(
            props.itemType,
            props.link,
            props.professionalLink
        ),
    };
};

export const getTileEditableItems = ({
    professional,
    user,
    itemType,
    itemLink,
}: {
    professional?: Pick<ProfessionalTileFragment, 'ID' | 'Link'> | null;
    user?: User;
    itemType: ContentItemType;
    itemLink: string;
}) => {
    const editableItems =
        professional?.ID && user
            ? getEditableItem({
                  itemType: itemType,
                  link: itemLink,
                  professionalID: professional.ID,
                  professionalLink: professional?.Link || '',
                  user,
              })
            : undefined;
    return editableItems;
};

interface VisitBMEditorOptions {
    repositionThumb?: boolean;
}

export const visitBMEditor = (
    item: EditableItem,
    sameTab?: boolean,
    options?: VisitBMEditorOptions
) => {
    // Navigate to bm editor on the old website.
    if (window && item.bmEditorLink) {
        let bmLink = item.bmEditorLink;
        if (options?.repositionThumb && item.repositionThumbLink) {
            bmLink = item.repositionThumbLink;
        }

        window.open(bmLink, sameTab ? '_parent' : '_blank');
    }
};
