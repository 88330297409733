import { useAppDisplayModeContext } from '@archipro-design/aria';
import { useFetcher } from '@remix-run/react';
import React from 'react';
import type { LoaderResult } from '~/routes/remix-api.directory.get-directory-items-admin';
import { QUERY_DIRECTORY_ITEMS_ADMIN } from '~/routes/remix-api.directory.get-directory-items-admin';
import type { DirectoryItemAdmin } from '../type';

/**
 * Currently, we query category items data from ES.
 * There are some extra admin data we need to fetch from DB.
 * We can use this hook to fetch extra data on the client.
 * We don't want to
 *   - add the data to ES (normally not advisable to bloat the es document with data not used for search as it negatively impacts performance)
 *   - waterfall request on the loader (not great for performance either)
 * @param isAdmin
 * @param itemIDs
 * @returns
 */
export const useItemAdminData = (canEditAdmin: boolean, itemIDs: number[]) => {
    const { desktop: isDesktop } = useAppDisplayModeContext();
    const fetcher = useFetcher<LoaderResult>();
    itemIDs.sort();

    // Query extra admin data
    React.useEffect(() => {
        if (!isDesktop || !canEditAdmin || !itemIDs.length) {
            return;
        }

        const formData = new FormData();
        itemIDs.forEach((id) => formData.append('IDs', id.toString()));
        fetcher.submit(formData, {
            method: 'get',
            action: QUERY_DIRECTORY_ITEMS_ADMIN,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDesktop, canEditAdmin, JSON.stringify(itemIDs)]);

    const adminData = fetcher.data?.items || [];
    const adminTileMap = adminData.reduce((acc, item) => {
        acc[item.id] = item;
        return acc;
    }, {} as { [key: number]: DirectoryItemAdmin });

    return adminTileMap;
};
