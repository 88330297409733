import { getBMPagePath } from './get-bm-page-path';

export type ContentItemType =
    | 'projects'
    | 'products'
    | 'articles'
    | 'professionals';

const linkToURLSegment = (link: string): string => {
    if (!link || !link.length) {
        return '';
    }

    // TODO: check url patterns for all directory item links (tbc: magazine)
    let pathList = link.split('/').filter((i) => !!i);
    if (pathList.length > 0) {
        pathList = pathList.splice(-1);
    }

    return pathList.length ? pathList.join('/') : '';
};

export const getContentEditorLink = (
    itemType: ContentItemType,
    itemLink: string,
    professionalLink: string
): string => {
    const urlSegment = linkToURLSegment(itemLink);
    const professionalURLSegment = linkToURLSegment(professionalLink);
    return getContentEditorLinkByURLSegment(
        itemType,
        urlSegment,
        professionalURLSegment
    );
};

export const getRepositionThumbLink = (
    itemType: ContentItemType,
    itemLink: string,
    professionalLink: string
): string => {
    let editLink = getContentEditorLink(itemType, itemLink, professionalLink);
    if (itemType === 'professionals') {
        const professionalURLSegment = linkToURLSegment(professionalLink);
        editLink = getBMPagePath(
            professionalURLSegment,
            'profile/business-information'
        );
    } else if (itemType === 'projects' || itemType === 'articles') {
        editLink += '/page-builder';
    }

    return `${editLink}?repositionThumb=1`;
};

export const getContentEditorLinkByURLSegment = (
    itemType: ContentItemType,
    itemURLSegment: string,
    professionalURLSegment: string
): string => {
    return getBMPagePath(professionalURLSegment, itemType, itemURLSegment);
};

export const getProfessionalEditorLink = (professionalLink: string): string => {
    const professionalURLSegment = linkToURLSegment(professionalLink);
    return getBMPagePath(professionalURLSegment, 'profile');
};
